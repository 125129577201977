import React from 'react';
// import Parallax from 'parallax-js'

export default class Slider extends React.Component{

    render(){
        return (
			// Slider Start
			<section id="slider" className="slider-area">	
				<div className="slider-wrapper">
					<div className="single-slide height-100vh">
						<div className="slider-content">
							<div className="text-content-wrapper">
								<div className="text-content">
									<h5 style={{paddingTop: "0px"}} >Welcome</h5>
									<h2>My Name is Xinpei Tan</h2>
									<p>I'm a Software Development Engineer</p>
									<a href="/resume/Xinpei Tan_2020.pdf" download className="default-btn">download resume</a>
								</div>
							</div>
						</div>
						{/* <div ref="scene">
							<img data-depth="2" src="img/logo/logo.png"/>
						</div> */}
					</div>
				</div>
			</section>
        );
	}
	componentDidMount() {
		// var scene = this.refs.scene;
		// var parallaxInstance = new Parallax(scene, {
		// 	relativeInput: true
		// });
	}
}