import React from 'react';
import { Icon, Popover } from 'antd';

export default class Footer extends React.Component{

    render(){
        return (
			// Footer Start
			<div className="footer-area text-center">
				<div className="footer-main pt-60 pb-60">
					<div className="about-container">
						<div className="footer-row">
							<div className="footer-size">
								<div className="footer-logo">
								<a href="index.html"><img src="img/logo/logo.png" alt="bizniz"/></a> 
								</div>
								<div className="footer-content">
									<p>Palo Alto, CA 94303</p>
									<p>
										Email : <a href="mailto:t347100955@gmail.com">t347100955@gmail.com</a>
									</p>
									<p>
										Phone : 
										<a className="phone-number-info" href="tel:+19712408736">+1 971 240 8736</a> 
									</p>
									<div className="footer-social">
										<ul>
											<li><a href="mailto:t347100955@gmail.com"><Icon type="mail" /></a></li>
											<li><a href="https://github.com/widmonstertony" rel="noopener noreferrer" target="_blank"><Icon type="github" theme="filled"/></a></li>
											<li>
												<Popover trigger="click" placement="topLeft" content={<div style={{ width: "300px", height: "398px" }}>
													<img src="img/footer/wechat.jpg" alt="wechat"/></div>} title="Wechat QR Code">
													<a><Icon type="wechat"/></a>
												</Popover>
											</li>
											<li>
												<Popover trigger="click" placement="topLeft" content={<div style={{ width: "300px", height: "400px" }}>
													<img src="img/footer/qq.jpg" alt="qq"/></div>} title="QQ QR Code">
													<a><Icon type="qq" /></a>
												</Popover>
											</li>
											<li><a href="https://www.linkedin.com/in/xinpei-tan/" rel="noopener noreferrer" target="_blank"><Icon type="linkedin" theme="filled"/></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>  
					</div>
				</div>
				<div className="footer-bottom pt-12 pb-14 text-center">
					<div className="container">
						<div className="footer-row">
							<div className="footer-copyright">
								<p>2020. Xinpei Tan. Created using ReactJs.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
        );
	}
	componentDidMount() {
	}
}