import React, { lazy, Suspense } from 'react';
import About from "./About.jsx";
import Slider from "./Slider.jsx";
import Footer from "./Footer.jsx";
import { BackTop, Button, Icon, Drawer, Skeleton } from 'antd';


const PortfolioBoard = lazy(() => import('./PortfolioBoard.jsx'))
const Education = lazy(() => import('./Education.jsx'))

export default class Home extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			MenuVisible: false,
		}
	}
    render(){
		const loadingComponent = <div style={{width: "100%", marginBottom: "50px"}}>
									<div style={{width: "80%", margin: "0 auto"}}>
										<Skeleton active />
									</div>
								</div>;
        return (
			// Header Area Start
			<div>
				<div id="header-area" className="header-area header-sticky">
					<div className="container">
						<div className="row">
							<div className="header-size" style={{width: "80%", margin: "0 auto", marginBottom: "20px"}}>
								<a href="#me" className="logo float-left"><img src="img/logo/logo.png" alt="logo"/></a>
								<Button icon="project" className="menu-open" onClick={()=> {this.setState({MenuVisible: true})}}></Button>
							</div>
						</div>
					</div>
				</div>             
				<Drawer
						title="Xinpei Tan"
						placement={"right"}
						closable={true}
						onClose={() => {
							this.setState({
								MenuVisible: false,
							})} }
						visible={this.state.MenuVisible}
						>
						<p><a href="/mememaster" rel="noopener noreferrer" target="_blank">MemeMaster</a></p>
						<p><a href="https://crowdtrace.caida.org/" rel="noopener noreferrer" target="_blank">Quince</a></p>
						<p><a href="https://flyerfighter.ucsdtriplec.org/" rel="noopener noreferrer" target="_blank">FlyerFighter</a></p>
						<p><a href="/wetrip" rel="noopener noreferrer" target="_blank">WeTrip</a></p>
						<p><a href="https://ucsdtriplec.org/html/projectPage_androidtryway.html" rel="noopener noreferrer" target="_blank">TriWay</a></p>
						<p><a href="https://youtu.be/kvadtZz3ujI" rel="noopener noreferrer" target="_blank">MeetSB</a></p>
						<p><a href="https://cse125.ucsd.edu/2019/cse125g2/" rel="noopener noreferrer" target="_blank">Doginator 2</a></p>

						{/* <p><a href="/Jupiter" rel="noopener noreferrer" target="_blank">Event Recommendation</a></p>
						<p><a href="/wetrip" rel="noopener noreferrer" target="_blank">WeTrip</a></p>
						<p><a href="/around" rel="noopener noreferrer" target="_blank">Around</a></p> */}
				</Drawer>               
				{/* Header Area End */}
				{/* Background Area Start  */}
				<Slider></Slider>
				{/* Background Area End */}
				{/* About Start */}
				<About></About>
				{/* <!-- About End --> */}
				{/* <!-- Service Start --> */}
				<Suspense fallback={loadingComponent}>
					<Education></Education>
				</Suspense>
				{/* <!-- Service Area End --> */}
				{/* <!-- latest area start --> */}
				<Suspense fallback={loadingComponent}>
					<PortfolioBoard></PortfolioBoard>
				</Suspense>
				<Footer></Footer>
				<BackTop id="scrollUp">
					<Icon type="arrow-up" />
				</BackTop>
			</div>
        );
	}
	componentDidMount() {
	}
}