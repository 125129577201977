import React from 'react';

export default class About extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
		}
	}

    render(){
	
        return (
			// About Start
			<section id="me" className="about-area pt-100 pb-100 bg-light">
				<div className="about-container">
					<div className="about-row">
						<div className="about-size">
							<div className="about-image">
								<div className="image-bg">
									<img src="img/about/me.jpg" alt="me"/>  
								</div>
							</div>
						</div> 
						<div className="about-size">
							<div className="about-content">
								<div className="section-title"> 
									<h3>about <span>me</span></h3>
								</div>
								<p className="bold">
									I'm eager to secure a full-time developer position at 2021 that will allow me to utilize my problem solving skills and attention to detail to further develop my abilities in the field of computer science.
								</p>
								<p>
									Computer Language Skills：Java, Javascript, HTML, CSS, C++, C, Python
								</p>
								<p>
									Tools: Git, MongoDB, mySQL, Vim, Tomcat, Apache, Nginx, Android Studio
								</p>
								<p>
									Web: NodeJs with ReactJs, ExpressJs, AWS, Firebase, Spring, PHP, Meteor, Taro
								</p>
								<a href="/resume/Xinpei Tan_2020.pdf" download className="default-btn">
									download resume
								</a>
							</div>
						</div> 
					</div>
				</div>
			</section>
        );
	}
	componentDidMount() {
	}
}